<template>
  <div class="wrapper" id="apply_done_wrapper">
    <div class="img_wrapper">
      <img :src="require('./apply_done@2x.png')" alt="" />
    </div>
    <p class="title">预约申请已提交</p>
    <p class="subtitle">等待输注中心审核</p>
    <button class="btn_detail" @click="handleApplyDetail">查看预约详情</button>
    <button class="btn_record" @click="handleApplyList">返回预约记录</button>
  </div>
</template>

<script>
export default {

  data () {
    return {
      queryObj: {}
    }
  },
  mounted () {
    const height = document.body.clientHeight
    const wrapperRef = document.getElementById('apply_done_wrapper')
    wrapperRef.style.height = height + 'px'
  },

  created () {
    this.queryObj = this.getQueryStringArgs()
  },

  methods: {

    // 获取url query参数方法
    getQueryStringArgs () {
      const qs = location.search.length > 0 ? location.search.substring(1) : ''
      const args = {};
      const arr = qs.split('&').map((kv) => kv.split('='));
      arr.forEach((item) => {
        args[decodeURIComponent(item[0])] = decodeURIComponent(item[1]);
      });
      return args;
    },

    handleApplyDetail () {
      this.$router.push({
        path: '/preengage-detail',
        query: {
          id: this.queryObj.id
        }
      })
    },

    handleApplyList () {
      this.$router.push('/applyList')
    },
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  // height: 100%;
  background: #fff;

  .img_wrapper {
    margin: 0 auto 0 auto;
    padding-top: 90px;
    display: inline-block;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 76px;
      height: 72px;
    }
  }

  .title {
    // width: 140px;

    height: 32px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 20px;
    color: #feb520;
    line-height: 32px;
    text-align: center;
    font-style: normal;
  }

  .subtitle {
    // width: 112px;
    height: 32px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #9a9ea8;
    line-height: 32px;
    text-align: center;
    font-style: normal;
  }

  .btn_detail {
    display: block;
    margin: 50px auto 14px auto;
    width: 132px;
    height: 44px;
    background: #ffffff;
    border-radius: 40px;
    border: 1px solid #f36218;
    padding: none;
    color: #f36218;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    line-height: 44px;
    text-align: center;
    font-style: normal;
  }

  .btn_record {
    display: block;
    margin: 0 auto;
    width: 132px;
    height: 44px;
    background: #f36218;
    border-radius: 40px;
    border: none;

    // width: 96px;
    // height: 22px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    line-height: 44px;
    text-align: center;
    font-style: normal;
  }
}
</style>