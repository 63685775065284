<template>
  <div class="wrapper" id="apply_wrapper">
    <!-- 中心信息 -->
    <infusion-center-info @navigate="handleNavigate" :centerInfo="centerInfo" />

    <!-- 预约时间 -->
    <infusion-time-select
      @selectTime="handleSelectTime"
      :daysList="daysList"
      :isReview="false"
    />

    <!-- 患者信息 -->
    <van-form ref="formRef">
      <div class="basic_info">
        <div class="title">
          <img class="img basic_img" src="~@img/cardInfo/基本信息.svg" alt="" />
          患者信息
        </div>
        <van-cell-group>
          <van-field
            v-model="patientName"
            label="姓名"
            input-align="right"
            required
            clearable
            clear-trigger="always"
            :rules="[{ required: true, message: '请输入患者姓名' }]"
            error-message-align="right"
            maxlength="20"
          />
          <van-field
            v-model="patientPhone"
            label="联系方式"
            input-align="right"
            required
            clearable
            clear-trigger="always"
            :rules="[
              { required: true, message: '请填写联系方式' },
              {
                validator,
                message: '请输入正确的联系方式',
              },
            ]"
            error-message-align="right"
            @blur="handlePhoneBlur"
            :error-message="phoneErrorMessage"
          />
          <van-field
            v-model="patientAge"
            label="年龄"
            input-align="right"
            required
            clearable
            clear-trigger="always"
            :rules="[{ required: true, message: '请填写年龄' }]"
            error-message-align="right"
            type="digit"
            maxlength="120"
            @input="handleAgeInput"
          />
        </van-cell-group>
      </div>

      <div class="basic_info">
        <div class="title drug_title" @click="handleToSelect">
          <div class="title_name">
            <img class="drug_img" src="~@img/cardInfo/药品信息.svg" alt="" />
            <span>药品信息</span>
          </div>

          <span class="select">去选药</span>
        </div>
        <van-cell-group>
          <div>
            <SelectedDrugItem
              v-for="item in selectedDrugs"
              :item="item"
              @onRemoveItem="handleRemove"
            />
          </div>
        </van-cell-group>
      </div>

      <div class="basic_info">
        <div class="title">
          <img class="other_img" src="~@img/cardInfo/其他信息.svg" alt="" />
          <span>其他信息</span>
        </div>
        <van-cell-group>
          <van-field
            v-model="diagnoseName"
            label="疾病诊断"
            input-align="right"
            required
            clearable
            clear-trigger="always"
            :rules="[{ required: true, message: '请填写疾病诊断' }]"
            error-message-align="right"
            maxlength="200"
          />
          <van-field
            name="infusionType"
            label="给药方式"
            input-align="right"
            required
          >
            <template #input>
              <button
                :class="[+infusionType === 1 ? 'way2' : '', 'way1']"
                @click="handleChangeInfusionType(1)"
              >
                静脉输注
              </button>
              <button
                :class="[+infusionType === 2 ? 'way2' : '', 'way1']"
                @click="handleChangeInfusionType(2)"
              >
                皮下注射
              </button>
            </template>
          </van-field>
          <van-field
            v-model="remark"
            label="特殊备注"
            input-align="right"
            placeholder="请输入您的其他要求，最多30字"
            clearable
            clear-trigger="always"
            :rules="[{ required: false, message: '请填写特殊备注' }]"
            error-message-align="right"
            maxlength="30"
          />
        </van-cell-group>
      </div>
    </van-form>

    <!-- 上传图片 -->
    <Uploader
      :photoList="photoList"
      :needEdit="true"
      :needSupplementary="false"
      @onChooseImg="onChooseImg"
      @onDelPhoto="onDelPhoto"
    />

    <!-- 协议文案 -->
    <div class="note">
      <p class="title"><span class="required">*&nbsp;</span>预约须知</p>
      <p class="subTitle">输注需提供材料：</p>
      <p class="notice">具体以输注中心咨询及确认结果为准</p>
      <p class="content" v-html="materialContent"></p>

      <p class="subTitle">预约输注流程：</p>
      <p class="content process">
        <span class="bold">预约申请</span>
        <span class="plain">患者填写并选择预约时间</span>
      </p>
      <p class="content process">
        <span class="bold">预约确认</span>
        <span class="plain"
          >输注中心确认预约输注时间，如遇时间冲突，输注中心将与患者联系更改</span
        >
      </p>
      <p class="content process">
        <span class="bold">药品配送</span>
        <span class="plain">思派药房按输注中心确认时间配送至输注中心</span>
      </p>
      <p class="content process">
        <span class="bold">按时到店</span>
        <span class="plain">患者按预约时间携带必备资料至输注中心</span>
      </p>
      <p class="content process">
        <span class="bold">核对输注</span>
        <span class="plain"
          >输注中心检查药品完整性，核对患者预约信息、病历及处方资料无误后进行输注</span
        >
      </p>
    </div>

    <!-- 底部按钮 -->
    <BottomBtns
      :btns="[
        {
          text: '暂存',
          type: 'plain',
          eventName: 'temprory',
        },
        {
          text: '提交',
          type: 'primary',
          eventName: 'onSave',
        },
      ]"
      @onSave="onSave"
      @temprory="onTemprory"
    />
  </div>
</template>

<script>
import InfusionCenterInfo from '@/components/infusion-center-info/index.vue';
import InfusionTimeSelect from '@/components/infusion-time-select/index.vue'
import BottomBtns from '@/components/bottom-btns/index.vue';
import MedicineSelect from '@/components/medicine-select/index.vue'
import SelectedDrugItem from '@/components/selectedDrugItem/index.vue'
import Uploader from '@/components/uploader';
import api from '@/api'
import WxTool from '@/utils/wx-tool';
import eventBus from '../../../src/eventBus/index'
import { getFileSize, base64ToFile } from '@/utils';
import { Toast } from 'vant'

export default {
  components: {
    InfusionCenterInfo,
    InfusionTimeSelect,
    BottomBtns,
    MedicineSelect,
    SelectedDrugItem,
    Uploader
  },
  data () {
    return {
      selectedDrugs: [],
      patientName: '',
      patientAge: '',
      patientPhone: '',
      // 疾病
      diagnoseName: '',
      remark: '',
      centerInfo: {},
      daysList: [],
      infusionType: 1,
      infusionDate: '',
      infusionTime: '',
      infusionTimeCode: '',
      infusionWeek: '',
      phoneErrorMessage: '',
      materialContent: '',
      photoList: [],
      localIds: [],
      LocalImgData: [],
      tipData: []
    };
  },
  created () {
    const centerCode = this.$route.query.id
    // 再次预约
    const again = this.$route.query.again
    if (+again === 1) {
      if (!sessionStorage.getItem('cacheFormInfo')) {
        // 再次预约， 获取上一次预约信息，带入
        this.getLastApplyInfo(this.$route.query.applyId)
      } else {
        if (sessionStorage.getItem('cacheFormInfo')) {
          const cacheFormInfo = JSON.parse(sessionStorage.getItem('cacheFormInfo') || "{}")
          const { patientName, patientPhone, patientAge, diagnoseName, infusionType, remark, photoList, id } = cacheFormInfo
          this.patientName = patientName
          this.patientPhone = patientPhone
          this.patientAge = patientAge
          this.diagnoseName = diagnoseName
          this.infusionType = infusionType
          this.remark = remark
          this.photoList = photoList || []
          if (id) {
            this.id = id
          }
          // 清空缓存内容
          sessionStorage.removeItem('cacheFormInfo')
        }

        if (sessionStorage.getItem('selectedWareList')) {
          this.selectedDrugs = JSON.parse(sessionStorage.getItem('selectedWareList') || "[]")
          // 清空缓存内容
          sessionStorage.removeItem('selectedWareList')
        }
      }
    } else {
      // 非再次预约，获取临时保存的数据
      if (sessionStorage.getItem('cacheFormInfo')) {
        const cacheFormInfo = JSON.parse(sessionStorage.getItem('cacheFormInfo') || "{}")
        const { patientName, patientPhone, patientAge, diagnoseName, infusionType, remark, photoList, id } = cacheFormInfo
        this.patientName = patientName
        this.patientPhone = patientPhone
        this.patientAge = patientAge
        this.diagnoseName = diagnoseName
        this.infusionType = infusionType
        this.remark = remark
        this.photoList = photoList || []
        if (id) {
          this.id = id
        }
        // 清空缓存内容
        sessionStorage.removeItem('cacheFormInfo')

        if (sessionStorage.getItem('selectedWareList')) {
          this.selectedDrugs = JSON.parse(sessionStorage.getItem('selectedWareList') || "[]")
          // 清空缓存内容
          sessionStorage.removeItem('selectedWareList')
        }
      } else {
        this.getTemporarySavedApplyInfo(centerCode)
      }
    }
    // 中心详情
    this.getDetail(centerCode)
    // 可预约时间
    this.getAvaibleApplyTime(centerCode)
  },

  // destroyed () {
  //   sessionStorage.removeItem('selectedWareList')
  //   sessionStorage.removeItem('cacheFormInfo')
  // },

  // activated () {
  //   console.log('1 activated');
  //   // 读取缓存内容
  //   this.selectedDrugs = JSON.parse(sessionStorage.getItem('selectedWareList') || "[]")
  //   // 清空缓存内容
  //   sessionStorage.removeItem('selectedWareList')
  // },

  // deactivated () {
  //   console.log('2 deactivated');
  // },

  beforeRouteLeave (to, from, next) {
    // to，要去的，from，当前的
    // console.log('beforeRouteLeave', to, from);
    // if (to.path === "/selectMedicine") {
    //   console.log('keepAlive');
    //   from.meta.keepAlive = true;
    // } else {
    //   this.$destroy()
    //   console.log('not keepAlive');
    //   from.meta.keepAlive = true;
    // }
    next();
  },
  mounted () {
    eventBus.$on('selectedWares', (data) => {
      console.log('selectedWares Bus', data);
    })
  },

  watch: {
  },
  methods: {
    handleRemove (item) {
      const findIndex = this.selectedDrugs.findIndex(item1 => {
        return item.goodsId === item1.goodsId
      })
      if (findIndex > -1) {
        const copyData = JSON.parse(JSON.stringify(this.selectedDrugs))
        copyData.splice(findIndex, 1)
        this.selectedDrugs = copyData
      }
    },
    // 去选择商品
    handleToSelect () {
      sessionStorage.setItem('selectedWareList', JSON.stringify(this.selectedDrugs || []))
      // 缓存
      const cacheFormInfo = {
        patientName: this.patientName,
        patientPhone: this.patientPhone,
        patientAge: this.patientAge,
        diagnoseName: this.diagnoseName,
        infusionType: this.infusionType,
        remark: this.remark,
        photoList: this.photoList || [],
        id: this.id || undefined
      }
      sessionStorage.setItem('cacheFormInfo', JSON.stringify(cacheFormInfo))
      this.$router.push({
        path: '/selectMedicine',
        query: {
          isApply: "true",
          cityName: this.centerInfo.cityName,
          infusionCenterCode: this.centerInfo.infusionCenterCode
        }
      })
    },
    mapMaterial (id) {
      const map = {
        1: '出院小结/病历',
        2: '医生处方',
        3: '购药凭证',
        4: '近期肝肾功能血常规检查单',
        5: '近期血检报告',
        // 6: '其他',
      }
      return map[id]
    },
    validator (val) {
      return /^[1][3-9][0-9]{9}$/.test(val);
    },
    validatePhoneNumber (number) {
      const regEx = /^[1][3-9][0-9]{9}$/;
      return regEx.test(number);
    },
    handlePhoneBlur (value) {
      this.phoneErrorMessage = this.validatePhoneNumber(this.patientPhone) ? '' : '请输入正确的手机号'
    },
    handleAgeInput (value) {
      if (value < 0) {
        this.patientAge = 0
      }
      if (value > 120) {
        this.patientAge = 120
      }
    },

    // 获取详情（再次预约的时候触发）
    async getLastApplyInfo (id) {
      const res = await api.applyDetail({
        id
      })

      if (res.code === '0' && res.data) {
        const {
          patientName,
          patientPhone,
          patientAge,
          diagnoseName,
          infusionType,
          remark,
          id,
          goodDTOs,
          photoList,
        } = res.data

        this.patientName = patientName
        this.patientPhone = patientPhone
        this.patientAge = patientAge
        this.diagnoseName = diagnoseName
        this.infusionType = infusionType
        this.remark = remark
        // this.id = id
        this.selectedDrugs = goodDTOs
        this.photoList = photoList || []
      }
    },

    // 获取暂存数据
    async getTemporarySavedApplyInfo (infusionCenterCode) {
      try {
        const res = await api.getTemporarySavedApplyInfo({
          infusionCenterCode
        })

        // 有暂存数据，取值
        if (res.code === '0' && res.data) {
          const {
            patientName,
            patientPhone,
            patientAge,
            diagnoseName,
            infusionType,
            remark,
            id,
            goodDTOs,
            infusionImages,
          } = res.data

          this.patientName = patientName
          this.patientPhone = patientPhone
          this.patientAge = patientAge
          this.diagnoseName = diagnoseName
          this.infusionType = infusionType
          this.remark = remark
          this.id = id
          this.selectedDrugs = goodDTOs
          this.photoList = infusionImages || []
        }
      } catch (error) {
        console.log(error);
      }
    },

    handleChangeInfusionType (type) {
      this.infusionType = type
    },

    // 导航
    handleNavigate () {
      const instance = WxTool.getInstance();
      instance.navigate(this.centerInfo.latitude, this.centerInfo.longitude)
    },

    // 中心详情
    async getDetail (infusionCenterCode) {
      const res = await api.getInfusionDetail({
        infusionCenterCode
      })

      this.centerInfo = res.data
      this.materialContent = res.data.infusionMaterial && res.data.infusionMaterial.split(',').map(material => {
        return this.mapMaterial(material)
      })
      if (res.data.infusionMaterial && res.data.infusionMaterial.split(',').includes('6')) {
        this.materialContent.pop()
        this.materialContent.push(res.data.infusionMaterialRemark)
      }
      this.materialContent = this.materialContent.map((item, index) => {
        return `<p>${this.mapIndex(index + 1)}${item}</>`
      })
      this.materialContent = this.materialContent.join('；')
    },

    mapIndex (index) {
      let result = ''
      switch (index) {
        case 1:
          result = '①'
          break;
        case 2:
          result = '②'
          break;
        case 3:
          result = '③'
          break;
        case 4:
          result = '④'
          break;
        case 5:
          result = '⑤'
          break;
        case 6:
          result = '⑥'
          break;
        case 7:
          result = '⑦'
          break;
        case 8:
          result = '⑧'
        case 9:
          result = '⑨'
          break;
        default:
          break;
      }
      return result
    },

    // 获取预约中心可预约时间
    async getAvaibleApplyTime (infusionCenterCode) {
      const res = await api.getAvaibleApplyTime({
        infusionCenterCode
      })
      this.daysList = res.data
    },

    // 确认选择时间
    handleSelectTime (day, time) {
      this.infusionDate = day.date
      this.infusionTime = time.time
      this.infusionTimeCode = time.timeCode
      this.infusionWeek = day.week
    },

    // 构造参数
    getSubmitParams (submitType) {
      const params = {
        "diagnoseName": this.diagnoseName,
        "infusionCenterCode": this.centerInfo.infusionCenterCode,
        "infusionCenterName": this.centerInfo.infusionCenterName,
        "infusionDate": this.infusionDate,
        "infusionMaterial": this.centerInfo.infusionMaterial,
        "infusionTime": this.infusionTime,
        "infusionTimeCode": this.infusionTimeCode,
        "infusionType": this.infusionType,
        "infusionWeek": this.infusionWeek,
        "patientAge": this.patientAge,
        "patientName": this.patientName,
        "patientPhone": this.patientPhone,
        "goodsDTOList": this.selectedDrugs.map(drug => {
          return {
            ...drug,
            goodsNum: drug.goodsNum
          }
        }),
        "remark": this.remark,
        userId: JSON.parse(localStorage.getItem('userInfo')).userId,
        "infusionStatus": submitType, // 状态 0 暂存 1 提交
        "mediaIds": this.photoList
      }
      // this.id: 暂存/再次预约 && 不是再次预约
      if (this.id && +this.$route.query.again !== 1) {
        params.id = this.id
      }
      return params;
    },

    // 临时保存
    async onTemprory () {
      try {
        const params = this.getSubmitParams(0)
        if (!params.hasOwnProperty('id')) {
          // 看看是否存在暂存数据
          const tempRes = await api.getTemporarySavedApplyInfo({
            infusionCenterCode: this.centerInfo.infusionCenterCode
          })
          if (tempRes.code === '0' && tempRes.data) {
            // 有暂存的数据，补充id
            params.id = tempRes.data.id
          }
        }
        const res = await api.applyTemporarySave(params)
        if (res.code === '0') {
          Toast.success('暂存成功')
          this.$router.go(-1)
        }
      } catch (error) {
        console.log('error', error)
      }
    },

    // 保存
    async onSave () {
      if (!this.infusionDate || !this.infusionTime) {
        return Toast.fail('请选择预约时间')
      }

      if (!this.selectedDrugs || this.selectedDrugs.length === 0) {
        return Toast.fail('请选择药品')
      }

      if (!this.photoList || (this.photoList && this.photoList.length === 0)) {
        return Toast.fail('请上传资料')
      }

      try {
        await this.$refs.formRef.validate()
        const params = this.getSubmitParams(1)
        const res = await api.applySave(params)
        if (res.code === '0') {
          Toast.success('提交成功')
          this.$router.push({
            path: '/appointmentApply/applyDone',
            query: {
              id: res.data
            }
          })
        }
      } catch (error) {
        console.log('error', error)
      }
    },

    onDelPhoto (index) {
      this.photoList && this.photoList.splice(index, 1);
    },

    async onChooseImg (chooseLocalIds) {
      console.log(chooseLocalIds)
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0,
      });
      this.localIds = [...chooseLocalIds]; // 记录原数组
      await this.getLocalImgData([...chooseLocalIds]);
    },

    async getLocalImgData (localIds) {
      const instance = WxTool.getInstance();
      const localId = localIds[0];
      const shiftedIndex = this.localIds && this.localIds.indexOf(localId);
      localIds.shift();
      instance.getLocalImgData(localId.toString(), async (res) => {
        // 计算图片大小
        if (res.indexOf('data:image') != 0) {
          //判断是否有这样的头部
          res = 'data:image/jpeg;base64,' + res
        }
        res = res.replace(/\r|\n/g, '').replace('data:image/jpg', 'data:image/jpeg');
        const fileSize = getFileSize(res); // 获取图片的大小，单位为KB

        console.log(fileSize, '681')
        console.log(fileSize > 5, '682')
        if (fileSize > 5) {
          console.log(fileSize, shiftedIndex, '683')
          this.tipData.push(shiftedIndex + 1);
        } else {
          this.LocalImgData.push(res)
        }
        // 图片大小符合要求，继续处理
        if (localIds.length > 0) {
          await this.getLocalImgData(localIds);
        } else {
          if (this.LocalImgData.length > 0) {
            this.uploadFlie(this.LocalImgData, this.tipData);
          } else { // 一张也没上传成功
            if (this.localIds.length === 1 && this.tipData.length === 1) {
              Toast.fail('图片太大了！');
            } else if (this.localIds.length > 1 && this.tipData.length > 0) {
              Toast.fail(`有${this.tipData.length}张图片太大了`);
            }
          }
          this.LocalImgData = [];
          this.tipData = [];
        }
      });
    },

    async uploadFlie (localData, tips) {
      const form = new FormData();
      for (let i = 0; i < localData.length; i++) {
        const file = base64ToFile(localData[i], '图片');
        form.append('files', file);
      }
      try {
        const res = await api.uploadFilePre(form);
        if (res && res.code === '0') {
          res.data && res.data.map((item) => {
            this.photoList.push(item.fileUrl)
          });
        }
      } catch (err) {
      } finally {
        Toast.clear();
      }
      if (this.localIds.length === 1 && tips.length === 1) {
        Toast.fail('图片太大了！');
      } else if (this.localIds.length > 1 && tips.length > 0) {
        Toast.fail(`有${tips.length}张图片太大了`);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  // text-align: center;
  // width: 100%;
  // background-color: rgb(52, 123, 232);
  // .bg {
  //   width: 100%;
  // }
  padding-bottom: 120px;
  .basic_info {
    margin: 8px 0;
    .title {
      padding: 12px;
      height: 22px;
      font-size: 16px;
      font-weight: 500;
      background: #fff;
      color: #3a3a3a;
      line-height: 22px;
      .basic_img {
        height: 22px;
        vertical-align: text-top;
        margin-left: 0px;
        margin-right: -1px;
        margin-top: -3px;
      }

      .other_img {
        height: 16px;
        vertical-align: text-top;
        margin-left: 2px;
        margin-right: 4px;
        margin-top: 0px;
      }
    }
    .drug_title {
      display: flex;
      .title_name {
        flex: 1;
      }
      .drug_img {
        height: 18px;
        vertical-align: text-top;
        margin-left: 0px;
        margin-right: 4px;
        margin-top: 1px;
      }
      .select {
        width: 100px;
        text-align: right;
        color: #ff8713;
        font-size: 15px;
        &::after {
          top: 4px;
          content: '';
          display: inline-block;
          width: 14px;
          height: 14px;
          background: url('./right_grey@2x.png');
          background-size: 14px 14px;
          vertical-align: text-top;
          margin-top: 2px;
          margin-left: 2px;
        }
      }
    }
  }

  .other_info {
    margin-bottom: 8px;
  }

  .note {
    background: #fff;
    padding: 15px 18px;

    .title {
      // width: 151px;
      height: 18px;
      font-weight: 500;
      font-size: 16px;
      color: #3a3a3a;
      line-height: 18px;
      text-align: center;
      font-style: normal;
      margin-bottom: 8px;
      .required {
        color: red;
      }
    }

    .subTitle {
      color: #3a3a3a;
      font-size: 16px;
      height: 22px;
      line-height: 22px;
      font-weight: 700px;
      margin-bottom: 4px;
    }

    .notice {
      color: #ff8243;
      padding-left: 16px;
      // text-indent: 16px;
      font-size: 14px;
      height: 22px;
      line-height: 22px;
      font-weight: 100px;
      // margin-bottom: 25px;
    }

    .content {
      color: #3a3a3a;
      padding-left: 16px;
      font-size: 14px;
      line-height: 22px;
      font-weight: 100px;
      margin-bottom: 10px;
      .bold {
        font-weight: 500;
        color: #333;
      }
    }

    .process {
      display: flex;
      .bold {
        width: 70px;
      }
      .plain {
        flex: 1;
      }
    }
  }

  .way1 {
    width: 76px;
    height: 30px;
    background: #ffffff;
    border-radius: 15px;
    border: 1px solid #f36218;
    padding: 0;
    margin-left: 10px;

    // width: 62px;
    // height: 21px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #ff8200;
    line-height: 30px;
    text-align: center;
    font-style: normal;
  }

  .way2 {
    width: 76px;
    height: 30px;
    background: #f36218;
    border-radius: 15px;
    border: 1px solid #f36218;
    padding: 0;
    margin-left: 10px;

    // width: 62px;
    // height: 21px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #fff;
    line-height: 30px;
    text-align: center;
    font-style: normal;
  }

  /deep/ .van-field--error .van-field__control,
  .van-field--error .van-field__control::placeholder {
    color: #323232 !important;
  }

  .test11 /deep/ .van-field--error .van-field__control::placeholder {
    color: #323232 !important;
  }
}
/deep/ .van-picker-column__item .van-ellipsis {
  font-size: 14px;
}
</style>